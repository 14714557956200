import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useInventoryData } from "../../DataProviders/InventoryDataProvider";

const InventoryContext = createContext(null);
export const useInventory = () => useContext(InventoryContext);
export const InventoryProvider = ({ children }) => {
  const { filterItems, categoryOptions } = useInventoryData();
  const [searchParams] = useSearchParams();

  const filterCategory = useMemo(() => {
    const str = searchParams.get("category");
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  }, [searchParams]);

  useEffect(() => {
    if (categoryOptions.includes(filterCategory)) {
      filterItems(filterCategory);
    }
  }, [categoryOptions, filterCategory, filterItems]);

  return (
    <InventoryContext.Provider value={{ filterCategory }}>
      {children}
    </InventoryContext.Provider>
  );
};
