import { initializeApp } from "firebase/app";
import { get as getData, getDatabase, ref } from "firebase/database";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAs5hX7v_kSlty8uzqETkFMVrwaF_n65WU",
  authDomain: "the-little-lights.firebaseapp.com",
  databaseURL:
    "https://the-little-lights-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "the-little-lights",
  storageBucket: "the-little-lights.appspot.com",
  messagingSenderId: "689863823379",
  appId: "1:689863823379:web:889e33c304bc1c2fd4efe6",
  measurementId: "G-EVCN87S002",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
export const dbRef = ref(db);
export const get = async (ref) =>
  await getData(ref()).then(async (res) => res.val());
export const invRef = () => ref(db, "inventory/");
export const itemRef = (id) => ref(db, `inventory/${id}`);
export const setRef = () => ref(db, "set/");
export const setRefById = (id) => ref(db, `set/${id}`);
