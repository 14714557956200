import React from "react";
import classnames from "classnames";
import "./index.scss";

export const Button = ({
  className,
  onClick,
  label,
  type = "button",
  disabled = false,
  children,
}) => {
  const baseClass = classnames(className, "Button");

  return (
    <button
      type={type}
      className={baseClass}
      onClick={onClick}
      disabled={disabled}
    >
      {label || children}
    </button>
  );
};
